// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-academia-tsx": () => import("./../../../src/pages/academia.tsx" /* webpackChunkName: "component---src-pages-academia-tsx" */),
  "component---src-pages-ask-a-question-tsx": () => import("./../../../src/pages/ask-a-question.tsx" /* webpackChunkName: "component---src-pages-ask-a-question-tsx" */),
  "component---src-pages-benchtop-tsx": () => import("./../../../src/pages/benchtop.tsx" /* webpackChunkName: "component---src-pages-benchtop-tsx" */),
  "component---src-pages-blog-articles-tsx": () => import("./../../../src/pages/blog-articles.tsx" /* webpackChunkName: "component---src-pages-blog-articles-tsx" */),
  "component---src-pages-book-a-demo-thank-you-tsx": () => import("./../../../src/pages/book-a-demo-thank-you.tsx" /* webpackChunkName: "component---src-pages-book-a-demo-thank-you-tsx" */),
  "component---src-pages-book-a-demo-tsx": () => import("./../../../src/pages/book-a-demo.tsx" /* webpackChunkName: "component---src-pages-book-a-demo-tsx" */),
  "component---src-pages-book-a-trial-thank-you-tsx": () => import("./../../../src/pages/book-a-trial-thank-you.tsx" /* webpackChunkName: "component---src-pages-book-a-trial-thank-you-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-d-1-eec-1-de-ae-05-4-aae-90-bd-a-3-a-14-c-72-bd-8-c-chat-tsx": () => import("./../../../src/pages/d1eec1de-ae05-4aae-90bd-a3a14c72bd8c/chat.tsx" /* webpackChunkName: "component---src-pages-d-1-eec-1-de-ae-05-4-aae-90-bd-a-3-a-14-c-72-bd-8-c-chat-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-form-submitted-thank-you-tsx": () => import("./../../../src/pages/form-submitted-thank-you-.tsx" /* webpackChunkName: "component---src-pages-form-submitted-thank-you-tsx" */),
  "component---src-pages-hotpip-tsx": () => import("./../../../src/pages/hotpip.tsx" /* webpackChunkName: "component---src-pages-hotpip-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industry-tsx": () => import("./../../../src/pages/industry.tsx" /* webpackChunkName: "component---src-pages-industry-tsx" */),
  "component---src-pages-interactive-validation-data-tsx": () => import("./../../../src/pages/interactive-validation-data.tsx" /* webpackChunkName: "component---src-pages-interactive-validation-data-tsx" */),
  "component---src-pages-newsroom-tsx": () => import("./../../../src/pages/newsroom.tsx" /* webpackChunkName: "component---src-pages-newsroom-tsx" */),
  "component---src-pages-pip-vs-hardness-tsx": () => import("./../../../src/pages/pip-vs-hardness.tsx" /* webpackChunkName: "component---src-pages-pip-vs-hardness-tsx" */),
  "component---src-pages-pip-vs-tensile-tsx": () => import("./../../../src/pages/pip-vs-tensile.tsx" /* webpackChunkName: "component---src-pages-pip-vs-tensile-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-request-a-quote-thank-you-tsx": () => import("./../../../src/pages/request-a-quote-thank-you-.tsx" /* webpackChunkName: "component---src-pages-request-a-quote-thank-you-tsx" */),
  "component---src-pages-request-a-quote-tsx": () => import("./../../../src/pages/request-a-quote.tsx" /* webpackChunkName: "component---src-pages-request-a-quote-tsx" */),
  "component---src-pages-request-a-technical-specification-tsx": () => import("./../../../src/pages/request-a-technical-specification.tsx" /* webpackChunkName: "component---src-pages-request-a-technical-specification-tsx" */),
  "component---src-pages-research-papers-tsx": () => import("./../../../src/pages/research-papers.tsx" /* webpackChunkName: "component---src-pages-research-papers-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-technical-articles-tsx": () => import("./../../../src/pages/technical-articles.tsx" /* webpackChunkName: "component---src-pages-technical-articles-tsx" */),
  "component---src-pages-training-and-conferences-tsx": () => import("./../../../src/pages/training-and-conferences.tsx" /* webpackChunkName: "component---src-pages-training-and-conferences-tsx" */),
  "component---src-pages-videos-tsx": () => import("./../../../src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */),
  "component---src-pages-warranty-registration-tsx": () => import("./../../../src/pages/warranty-registration.tsx" /* webpackChunkName: "component---src-pages-warranty-registration-tsx" */),
  "component---src-pages-webinars-tsx": () => import("./../../../src/pages/webinars.tsx" /* webpackChunkName: "component---src-pages-webinars-tsx" */),
  "component---src-templates-blog-article-page-tsx": () => import("./../../../src/templates/BlogArticlePage.tsx" /* webpackChunkName: "component---src-templates-blog-article-page-tsx" */)
}

